import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Form,
  Chart1,
  Chart2,
  Input,
  Button,
  Error,
  Paper,
  Title,
  Content,
  Lower,
  SubTitle,
} from "../components/UserAuthForm";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import SimpleBackdrop from "../components/CirProgress";
import Dialog from "@material-ui/core/Dialog";
import { errorLog } from '../components/ErrorLog'
import { makeStyles } from '@material-ui/core/styles';
import BlueArrow from '../images/blue_arrow_24.svg';

const useStyles = makeStyles((theme) => ({

  dialogBackground: {
    minWidth: "100vw",
    height: "100vh",
    background: "#F4F7FF",
    fontFamily: "Noto Sans JP",
    padding: "unset !important",
    justifyContent: "center",
  }
  
}));

function Login(props) {
  const classes = useStyles();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [user_id, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  function postLogin() {
    setShowProgBar(true);

    const UserId_task = () => {
      return new Promise( (callback) => {
        localStorage.setItem("currentUserId", user_id)
        setSignedInUser({
          uid: user_id
        });
        callback();
      })
    }

    const UserTokens_task = (result) => {
      return new Promise( (callback) => {
        localStorage.setItem("userAuthToken", result.data.token);
        setAuthTokens(result.data.token);
        localStorage.setItem("loginTime", new Date);
        callback();
      })
    }

    const setExpiration = (hours) => {
      return new Promise( (callback) => {
        let now = new Date().getTime();
        let setupTime = localStorage.getItem('setupTime');
        if (setupTime == null) {
          localStorage.setItem('setupTime', now);
        } else {
          if (now - setupTime > hours * 60 * 60 * 1000) {
            localStorage.clear();
            localStorage.setItem('setupTime', now);
          }
        }
        callback();
      })
    };

    axios
      .post(process.env.REACT_APP_AUTH_SERVER + "auth/login", {
        user_id,
        password,
      })
      .then((result) => {
        if (result.data.token.length > 0) {
          const setupTime = setExpiration(2);
          Promise.all([setupTime]).then(() => {
            const p1 = UserId_task();
            const p2 = UserTokens_task(result);

            // localStorage入力後にログインするように対応
            Promise.all([p1, p2]).then(() => {
              errorLog(false,localStorage.getItem("tokens"),"")
              setShowProgBar(false);
              setLoggedIn(true);
            });
          })

        } else {
          alert(result.data.message);
          setShowProgBar(false);
        }
        // setShowProgBar(false);
      })
      .catch((e) => {
        if (e.response?.status === 461 || e.response?.status === 463) {
          alert(e.response?.data?.message);
          window.location = `/ai-shop-development/reset-pwd?user_id=${user_id}&token=${e.response?.data?.token}`;
        }
        else{
          alert(e.response?.data?.message);
        }
        console.log(e)
        setShowProgBar(false);
      });
  }

  function postChangePwd() {
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + "auth/login", {
        user_id,
        password,
      })
      .then((result) => {
        if (result.data.token.length > 0) {
          window.location = `/ai-shop-development/reset-pwd?user_id=${user_id}&token=${result.data.token}`;
        } else {
          alert(result.data.message);
        }
      })
      .catch((e) => {
        if (e.response.status === 461 || e.response.status === 463) {
          window.location = `/ai-shop-development/reset-pwd?user_id=${user_id}&token=${e.response.data.token}`;
        }
        alert(e.response.data.message);
        setShowProgBar(false);
      });
  }

  function postResetPwd() {
    window.location = `/ai-shop-development/sendreset-pwd`;
  }

  const referer =
    props.location.state !== undefined
      ? props.location.state.referer
      : "/ai-shop-development";

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }
  return (
    <div>
      <Dialog open fullScreen={true}>
        <SimpleBackdrop open={showProgBar} />
        <Card className={classes.dialogBackground}>
          <div className="centering-items">
            <Title className="head_title">■ ユーザー認証</Title>
            <Content>
                <SubTitle>ユーザーID</SubTitle>
                <Form>
                  <Input
                    type="username"
                    name={user_id}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                  />
                </Form>
                <SubTitle>パスワード</SubTitle>
                <Form>
                  <Input
                    type="password"
                    name={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form>
            </Content>
            <Lower className="centering-items centering-items-row">
              <div className="submit_btn sp_submit_btn" onClick={postLogin}>ログイン</div>
              <div className="submit_btn sp_submit_btn" onClick={postChangePwd}>パスワード変更</div>
            </Lower>
            <div className="centering-items">
              <div onClick={postResetPwd} className="reset_btn">
                <div className="reset_btn_text">
                  パスワードをお忘れの方
                </div>
                <img src={BlueArrow} alt="arrow" className="next_btn" />
              </div>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  );
}
export default Login;
