import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  TextField,
  Modal,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import * as Const from './Const';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PredContent from './PredContent';
import CircularProgress from './CircularProgressWrapper';
import { between } from '../utils/ComputeDistance';
import { getNearbySearch } from '../utils/GetNearbySearch';
import getNearestStation from '../utils/GetStation';
// import getStationWithES from '../utils/GetStationWithES';
import { predict } from '../utils/Api';
import Alert from './ErrorAlert';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';
import { errorLog } from './ErrorLog'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing(1),
    margin: 0,
    minWidth: 100,
  },
  chip: {
    margin: theme.spacing(1),
    maxWidth: 100,
  },
  btn: {
    margin: theme.spacing(1),
  },
  input: {
    width: 58,
  },
  required: {
    color: 'blue !important',
  },
  number: {
    "& ::-webkit-inner-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0,
      '-moz-appearance': 'textfield',
    },
    "& ::-webkit-outer-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0,
      '-moz-appearance': 'textfield',
    },
  },
  inputGroupTitle: {
    textAlign: 'center',
    height: '30px',
    padding: '0px',
    color: 'gray',
    borderBottom: 'solid 1px darkgrey',
  },
  th1: {
    fontSize: '14px',
    color: 'gray',
    background: 'lightgray',
    fontWeight: 'lighter',
    textDecoration: 'underline',
    border: 'solid 1px darkgrey',
  },
  th2: {
    border: 'none',
    height: '5px',
  },
  th3: {
    border: 'none',
    height: '15px',
  },
  shopInputDialogItemNameCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '50%',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shopInputDialogItemValueCell: {
    fontSize: '14px',
    padding: '3px',
    paddingLeft: '0px',
    paddingBottom: '2px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '30px',
  },
  shopInputDialogAddressNameCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '20%',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shopInputDialogAddressValueCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '30px',
  },
  subDialogPaper: {
    minWidth: '620px',
  },
  dialogPaper: {
    maxWidth: '620px',
  },
  subDialogButton: {
    minWidth: '100px',
    fontWeight: 'normal',
    height: '30px',
    padding: '3px 13px',
  },
  subDialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  inputCustomerSpend: {
    width: '75px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputBox: {
    width: '30%',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputFloorSpace: {
    width: '40%',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputDistance: {
    width: '70px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputNearStation: {
    width: '80px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  input: {
    width: '80px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputStationName: {
    width: '140px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputWalk: {
    width: '50px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  inputDeposit: {
    width: '100px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '3px',
    borderRadius: '0',
  },
  select: {
    height: '20px',
    left: '3px',
    borderRadius: '0',
    marginRight: '5px',
  },
  selectLabel: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  selectRoot: {
    paddingLeft: '5px',
    padding: '0 14px',
    fontSize: '14px',
  },
  unit: {
    display: 'inline',
    justifyContent: 'center',
    paddingLeft: '3px',
    fontSize: '15px'
  },
  shopInputFont: {
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  dialogContentRoot: {
    padding: '8px 16px 24px',
  },
  dialogItemNameCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '80px',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  dialogItemValueCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '280px',
    height: '30px',
  },
  dialogItemNameSmallCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '100px',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  dialogItemValueSmallCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '120px',
    height: '30px',
  },
  readyColor: {
    color: 'blue',
    fontWeight: 'bold',
  },
  notReadyColor: {
    color: 'red',
    fontWeight: 'bold',
  },
  unconcernedColor: {
    color: 'black',
    fontWeight: 'bold',
  },
  checkbox: {
    padding: '0 5px 0 10px',
  },
  formControlLabelString: {
    fontSize: '14px',
    color: 'gray',
  },
  closeButtonPadding: {
    padding: '8px 12px',
  },
  textField: {
    borderRadius: '0',
  },
  progressDialog: {
    height: '200px',
    width: '200px',
  },
  disabledString: {
    color: 'black',
  },
  modal: {
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: "100px",
    backgroundColor: "white",
    width: "140px",
    height: "380px"
  },
  modalButton: {
    margin: "5px"
  },
  checkboxPosition: {
    position: "relative",
    left: "55px",
    top: "-312px"
  },
  modalTitle: {
    backgroundColor: "#D3D3D3",
    padding: "3px 0px 3px 10px",
    fontSize: "1rem",
    fontWeight: "400",
  },
  floorBox: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "133px",
    display: "inline-block"
  }
}));

export default function FormDialogModel(props) {
  const classes = useStyles();
  const {
    title,
    open,
    onClose,
    selectedPlace,
    placeName,
    google,
    map,
    area,
    frontage,
    photos,
    memo,
    nearestStation,
    setNearShopMarker,
    setCircle,
    setPredCount,
  } = props;
  const { authTokens, setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();
  const [showDialog, setShowDialog] = useState(false);
  const [isNearbyReady, setIsNearbyReady] = useState(false);
  const [isInputReady, setIsInputReady] = useState(false);
  const [state, setState] = useState({
    restaurant: true,
    supermarket: true,
    home_goods_store: true,
    department_store: false,
    drugstore: true,
  });
  const [predictionResult, setPredictionResult] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [errOpen, setErrOpen] = useState(false);
  const [tmpList, setTmpList] = useState(['']);
  const [progress, setProgress] = useState(false);
  const [finishPrediction, setFinishPrediction] = useState({});
  const [station, setStation] = useState({});
  const strShopInfo = Const.strShopInfo;
  const pullDownList = Const.pullDownList;
  const KeyShopInfo = Const.KeyShopInfo;
  const shopInputElement = Object.keys(Const.shopInputDefault)
  const [shopInputDefault, setShopInputDefault] = useState(Const.shopInputDefault);
  // 選択中の店舗
  const [shopInput, setShopInput] = useState(shopInputDefault);
  // ピンのついているすべての店舗
  const [shop, setShop] = useState({});
  const shopInputNameMapping = Const.shopInputNameMapping;
  const shopInputValueMapping = Const.shopInputValueMapping;
  const [nearShop, setNearShop] = useState({});
  const [handleModal, setHandleModal] = useState(false)
  
  let tmp = new Array(25)
  for (let i = 0; i < 25; i++) { tmp[i] = false }
  const [checkboxFlag, setCheckboxFlag] = useState(tmp)
  const [saveAry,setSaveAry] = useState(tmp)

  const openModal = () => { setHandleModal(true) }
  const closeModal = () => { setHandleModal(false) }
  const registerModal = () => { 
    setHandleModal(false)
    const list = document.getElementsByName("floor")
    let firstFlag = true
    let checkedFloor = ""
    for(let i=0; i < list.length; i++){
      if(list[i].checked && firstFlag) {
        checkedFloor = list[i].id
        firstFlag = false
      } else if(list[i].checked && !firstFlag) {
        checkedFloor = checkedFloor.concat(',', list[i].id)
      }
      checkboxFlag[i] = list[i].checked
    }
    setShopInput({ ...shopInput, ["出店階数"]: checkedFloor})
  }

  {/*
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  */}

  // 業態の仮の値を受け取る値に変更
  const [businessMapping, setBusinessMapping] = useState({});
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'demo') {
      if (query.get('business') !== null && query.get('business').split(',').length === 2) {
        let item = pullDownList['業態'];
        let queryItem = query.get('business').split(',');
        setBusinessMapping({[queryItem[0]]: item[1], [queryItem[1]]: item[2]});
        queryItem.unshift('');
        pullDownList['業態'] = queryItem;
      } else {
        let item = pullDownList['業態'];
        setBusinessMapping({'居酒屋・バル': item[1], '焼肉・ホルモン': item[2]});
        pullDownList['業態'] = ['','居酒屋・バル','焼肉・ホルモン','ラーメン'];
      }
    }
  }, []);

  const handlePrediction = async () => {
    // console.log(authTokens)
    const shopInputData = await createPostData();
    const userId = localStorage.getItem('currentUserId');
    console.log(shopInputData);
    setErrMsg('');
    setProgress(true);
    try {
      const results = await predict(shopInputData, userId, authTokens, setAuthTokens, setSignedInUser);
      setPredictionResult(prevState => ({...prevState, [shopInputData['address']]: results.data}));
      setFinishPrediction(prevState => ({...prevState, [shopInputData['address']]: true}));
      
      await handleSendPDF(results.data);
      setProgress(false);
    } catch (error) {
      console.log(error.response);
      let msg;
      if (error.response?.status == 422 || error.response?.status == 424 || error.response?.status == 425 || error.response?.status == 555 || error.response?.status == 556 || error.response?.status == 557 || error.response?.status == 567) {
        msg = error.response.data.detail
      } else if (error.toString().indexOf('500') > 0 || error.toString().indexOf('502') > 0 || error.toString().indexOf('503') > 0 || !error.response?.data?.error) {
        msg = 'システムエラーが発生しました。システム管理者に連絡してください。';
      } else {
        msg = error.response.data.error;
      }
      setErrMsg(msg);
      setErrOpen(true);
      setProgress(false);
    }
    setPredCount();
  };

  const handleSendPDF = async (data) => {
    let shopData = await createPostData("pdf");
    console.log(data)

    shopData = {
      ...shopData,
      predictions: data?.predictions *1.2,
      area: data?.return_data['都道府県面積'],
      average_income: data?.return_data['市区町村平均所得'],
      population: data?.return_data['都道府県人口'],
      rank: data?.return_data['市区町村平均所得順位'],
      households_number: data?.return_data['都道府県世帯数'],
      row_1: data?.return_data['飲食店事業所数15S5分徒歩'],
      row_2: data?.return_data['小売業事業所数15S5分徒歩'],
      row_3: data?.return_data['共同住宅1・2階建に住む主世帯人員増減15-105分徒歩'],
      row_4: data?.return_data['共同住宅6〜10階建に住む主世帯数増減15-105分徒歩'],
      row_5: data?.return_data['20-24歳女性人口増減15-105分徒歩'],
      row_6: data?.return_data['40-44歳男性人口155分徒歩'],
      row_7: data?.return_data['全産業事業所数15S5分徒歩'],
      row_8: data?.return_data['共同住宅11階以上建に住む主世帯数増減15-1010分徒歩'],
      row_9: data?.return_data['50-54歳女性人口増減15-1010分徒歩'],
      row_10: data?.return_data['共同住宅6〜10階建に住む主世帯数1510分徒歩'],
      row_11: data?.return_data['共同住宅11階以上建に住む主世帯人員増減15-1010分徒歩'],
      row_12: data?.return_data['一戸建主世帯人員増減15-1010分徒歩'],
      row_13: data?.return_data['40-44歳男性人口増減15-1010分徒歩'],
      row_14: data?.return_data['住宅に住むその他主世帯数増減15-105分自動車'],
      row_15: data?.return_data['45-49歳男性人口増減15-105分自動車'],
      row_16: data?.return_data['75歳以上女性人口増減15-105分自動車'],
      row_17: data?.return_data['25-29歳女性人口増減15-1010分自動車'],
      row_18: data?.return_data['一般世帯人員が7人以上増減15-1010分自動車'],
      row_19: data?.return_data['共同住宅6〜10階建に住む主世帯人員1510分自動車'],
      row_20: data?.return_data['30-34歳女性人口増減15-1010分自動車'],
      // ...shopData,
      // predictions: 20000 *1.2,
      // area: 0,
      // average_income: 0,
      // population: 0,
      // rank: 1,
      // households_number: 0,
      // row_1: 0,
      // row_2: 0,
      // row_3: 0,
      // row_4: 0,
      // row_5: 0,
      // row_6: 0,
      // row_7: 0,
      // row_8: 0,
      // row_9: 0,
      // row_10: 0,
      // row_11: 0,
      // row_12: 0,
      // row_13: 0,
      // row_14: 0,
      // row_15: 0,
      // row_16: 0,
      // row_17: 0,
      // row_18: 0,
      // row_19: 0,
      // row_20: 0,
    };
    console.log(shopData);

    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    try {
      const html = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + 'pdf/sendmail',
        {
          uid: uid,
          shopData: shopData,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        }
      );
      document.getElementById('p1').innerHTML = html.data.p1.toString();
    } catch(error) {
      switch (error.response?.status) {
        case 400:
        case 401:
          errorLog(true,authTokens,"2")
          setAuthTokens(null);
          setSignedInUser(null);
      }
      return;
    }


    document.getElementById('divtoPDF').hidden = false;
    const p1 = document.getElementById('p1');
    const pdf = new jsPDF('p', 'pt', 'a4');
    html2canvas(p1, {
      useCORS: true,
      dpi: 72,
    }).then((canvas) => {
      let pageData = canvas.toDataURL('image/jpeg');
      let width = pdf.internal.pageSize.width;
      pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const hh = today.getHours();
      const min = today.getMinutes();
      const ss = today.getSeconds();
      today = yyyy + mm + dd + '_' + hh + min + ss;

      const blob = pdf.output("blob");
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        savePdfData(reader.result,today);
      };
      
      setTimeout(() => {
        // pdf.save('predictionResult' + today + '.pdf');
        // window.open(pdf.output('bloburl', { filename: 'predictionResult' + today + '.pdf' }));
        document.getElementById('divtoPDF').hidden = true;
      }, 2000);
    });

  };

  const savePdfData = async (data, name) => {
    try {
      const formData = new FormData();
      const cur_user = JSON.parse(localStorage.getItem('user'));
      const uid = cur_user.uid;
      formData.append('uid', uid);
      formData.append("file", data);
      formData.append("filename", name);
      const html = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + 'pdf/sendmailwithfile',
        formData,
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'multipart/form-data boundary=--------sendfile',
          }
        }
      );
    } catch(error) {
      console.log("mail")
      switch (error.response?.status) {
        case 400:
        case 401:
          errorLog(true,authTokens,"2")
          setAuthTokens(null);
          setSignedInUser(null);
      }
      return;
    }
  };

  const handleCrtPDF = async () => {
    let shopData = await createPostData("pdf");
    console.log(predictionResult[placeName])
    shopData = {
      ...shopData,
      predictions: predictionResult[placeName]?.predictions *1.2,
      area: predictionResult[placeName]?.return_data['都道府県面積'],
      average_income: predictionResult[placeName]?.return_data['市区町村平均所得'],
      population: predictionResult[placeName]?.return_data['都道府県人口'],
      rank: predictionResult[placeName]?.return_data['市区町村平均所得順位'],
      households_number: predictionResult[placeName]?.return_data['都道府県世帯数'],
      row_1: predictionResult[placeName]?.return_data['飲食店事業所数15S5分徒歩'],
      row_2: predictionResult[placeName]?.return_data['小売業事業所数15S5分徒歩'],
      row_3: predictionResult[placeName]?.return_data['共同住宅1・2階建に住む主世帯人員増減15-105分徒歩'],
      row_4: predictionResult[placeName]?.return_data['共同住宅6〜10階建に住む主世帯数増減15-105分徒歩'],
      row_5: predictionResult[placeName]?.return_data['20-24歳女性人口増減15-105分徒歩'],
      row_6: predictionResult[placeName]?.return_data['40-44歳男性人口155分徒歩'],
      row_7: predictionResult[placeName]?.return_data['全産業事業所数15S5分徒歩'],
      row_8: predictionResult[placeName]?.return_data['共同住宅11階以上建に住む主世帯数増減15-1010分徒歩'],
      row_9: predictionResult[placeName]?.return_data['50-54歳女性人口増減15-1010分徒歩'],
      row_10: predictionResult[placeName]?.return_data['共同住宅6〜10階建に住む主世帯数1510分徒歩'],
      row_11: predictionResult[placeName]?.return_data['共同住宅11階以上建に住む主世帯人員増減15-1010分徒歩'],
      row_12: predictionResult[placeName]?.return_data['一戸建主世帯人員増減15-1010分徒歩'],
      row_13: predictionResult[placeName]?.return_data['40-44歳男性人口増減15-1010分徒歩'],
      row_14: predictionResult[placeName]?.return_data['住宅に住むその他主世帯数増減15-105分自動車'],
      row_15: predictionResult[placeName]?.return_data['45-49歳男性人口増減15-105分自動車'],
      row_16: predictionResult[placeName]?.return_data['75歳以上女性人口増減15-105分自動車'],
      row_17: predictionResult[placeName]?.return_data['25-29歳女性人口増減15-1010分自動車'],
      row_18: predictionResult[placeName]?.return_data['一般世帯人員が7人以上増減15-1010分自動車'],
      row_19: predictionResult[placeName]?.return_data['共同住宅6〜10階建に住む主世帯人員1510分自動車'],
      row_20: predictionResult[placeName]?.return_data['30-34歳女性人口増減15-1010分自動車'],
    };
    console.log(shopData);

    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    try {
      const html = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + 'pdf/create',
        {
          uid: uid,
          shopData: shopData,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        }
      );
      document.getElementById('p1').innerHTML = html.data.p1.toString();
    } catch(error) {
      switch (error.response?.status) {
        case 400:
        case 401:
          errorLog(true,authTokens,"2")
          setAuthTokens(null);
          setSignedInUser(null);
      }
      return;
    }


    document.getElementById('divtoPDF').hidden = false;
    const p1 = document.getElementById('p1');
    const pdf = new jsPDF('p', 'pt', 'a4');
    html2canvas(p1, {
      useCORS: true,
      dpi: 72,
    }).then((canvas) => {
      let pageData = canvas.toDataURL('image/jpeg');
      let width = pdf.internal.pageSize.width;
      pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const hh = today.getHours();
      const min = today.getMinutes();
      const ss = today.getSeconds();

      today = yyyy + mm + dd + '_' + hh + min + ss;
      setTimeout(() => {
        // pdf.save('predictionResult' + today + '.pdf');
        window.open(pdf.output('bloburl', { filename: 'predictionResult' + today + '.pdf' }));
        document.getElementById('divtoPDF').hidden = true;
      }, 200);
    });
  };

  // 数値の入力チェック
  const checkNumber = (key, value, min, max, isFloat, maxLength) => {
    if (isFloat) {
      let reg;
      if (key === '敷地面積') {
        reg = new RegExp(String.raw`(\d{1,5})(\.\d{1})?`);
      } else if (key === '間口' || key === '間口 ※車道のみ') {
        reg = new RegExp(String.raw`(\d{1,3})(\.\d{1})?`);
      } else if (key === '坪数') {
        reg = new RegExp(String.raw`(\d{1,4})(\.\d{0,2})?`);
      } else {
        reg = new RegExp(String.raw`(\d{1,3})(\.\d{1,2})?`);
      }
      value = value.indexOf(",") ? value.replace(",","") : value
      if (value === '') {
        value = ''
      } else if (typeof value !== `number` && value.match(reg) === null) {
        value = null
      } else if (min !== null && Number(value) < min) {
        value = null
      } else if (max !== null && Number(value) > max) {
        value = max
      } else {
        let n = 2
        value = value.match(reg)[0]
        if (key === "坪数" && value.substr(-1) != "."){
          value = Number(value)
        }else if (value.substr(-1) != "."){
          value = Math.floor(Number(value) * Math.pow(10, n)) / Math.pow(10, n)
        }
      }
    } else {
      let reg = new RegExp(String.raw`\d{1,${maxLength}}`)
      //カンマ削除
      var value = value.replace(/,/g, '');
      if (value === '') {
        value = ''
      } else if (typeof value !== `number` && value.match(reg) === null) {
        value = null
      } else if (min !== null && Number(value) < min) {
        value = null
      } else if (max !== null && Number(value) > max) {
        value = max
      } else {
        value = value.match(reg)[0]
        value = Number(value).toFixed(0)
      }
    }
    return value === null ? null : String(value)
  }

  const processNumber = (key, val) => {
    setShopInput({ ...shopInput, [key]: val });
  }

  const handleInputChange = (event, isFloat) => {
    let key = event.target.id;
    let val = event.target.value
    let maxLength = event.target.maxLength
    if (strShopInfo.indexOf(key) === -1) {
      if ((val = checkNumber(key, val, 0, null, isFloat, maxLength)) !== null) {
        processNumber(key, val)
      }
    } else {
      setShopInput({ ...shopInput, [key]: val })
    }
  }

  const handleInputKeyDown = (event, isFloat) => {
    if (isFloat) {
      if (event.key === 'e' || event.key === '-' || event.key === '+') {
        event.preventDefault()
      }
    } else {
      if (event.key === 'e' || event.key === '-' || event.key === '+' || event.key === '.') {
        event.preventDefault()
      }
    }
  }

  const setKobunruiList = (ooBunrui) => {
    let lst =
      ooBunrui === '(RS)ロードサイド'
        ? pullDownList['小分類'].slice(0, 4)
        : ooBunrui === '(BI)ビルイン'
        ? pullDownList['小分類'].slice(4, 5)
        : [];
    lst.unshift('');
    setTmpList(lst);
  };

  // 値から記号もしくは数値の部分のみを取得
  const changeSelectValue = (value) => {
    if (value in shopInputValueMapping) {
      return shopInputValueMapping[value];
    } else {
      return value;
    }
  };

  const handlePullDownChange = (event) => {
    const key = event.target.name;
    const v = event.target.value;
    if (key === '大分類') {
      setKobunruiList(v);
    }
    let val = strShopInfo.indexOf(key) === -1 && v !== '' ? Number(v) : v;
    setShopInput({ ...shopInput, [key]: val });
  };

  const handleErrDialogClose = () => {
    setErrMsg('');
    setErrOpen(false);
  };

  const convertFloorToNumber = (value) => {
    let ary = value.split(",")
    const convertDict = {
      "B4": -4, "B3": -3, "B2": -2, "B1": -1, "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6,
      "7": 7, "8": 8, "9": 9, "10": 10, "11": 11, "12": 12, "13": 13, "14": 14, "15": 15, "16": 16,
      "17": 17, "18": 18, "19": 19, "20": 20, "21以上": 30,
    }

    if(ary.length === 1) {
      return convertDict[value]
    } else if (ary.length > 1) {
      let adder = 0
      ary.map((floor) => {
        floor.indexOf("B") >= 0 && adder++
      })
      if(adder == 0) { return 103 }
      else if (adder == ary.length) { return 101 }
      else { return 102 }
    } 
  }

  const createPostData = async (type = "prediction") => {
    let shopInputData = { ...shop[placeName] };
    for (const [key, value] of Object.entries(shopInputData)) {
      if (key in shopInputNameMapping) {
        if (value === '') {
          shopInputData[shopInputNameMapping[key]] = null;
        } else if (key == '出店階数' && type == "pdf") {
          shopInputData[shopInputNameMapping[key]] = value
        } else if (key == '出店階数' && type == "prediction") {
          shopInputData[shopInputNameMapping[key]] = convertFloorToNumber(value)
        } else if (Object.keys(pullDownList).indexOf(key) > -1) {
          shopInputData[shopInputNameMapping[key]] = changeSelectValue(value);
        } else {
          shopInputData[shopInputNameMapping[key]] = value;
        }
        // 項目名変更前(日本語の項目)の値を削除
        if (key !== shopInputNameMapping[key]) {
          delete shopInputData[key];
        }
      }
    }
    delete shopInputData['敷地面積'];
    delete shopInputData['間口'];

    // 都道府県、市区町村取得
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    try {
      const result = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + 'address/city',
        {
          uid: uid,
          address: shopInputData['address'],
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        }
      );

      shopInputData['state'] = result.data.prefecture;
      shopInputData['city'] = result.data.city;
      return shopInputData;

    } catch (error) {
      switch (error.response?.status) {
        case 400:
        case 401:
          errorLog(true,authTokens,"3")
          setAuthTokens(null);
          setSignedInUser(null);
      }
      return;
    }
  };

  const getNearByInfo = async (location, shop_types) => {
    let foodCompetitor;
    let supermarketCompetitor;
    let homeGoodsStoreCompetitor;
    let drugstoreCompetitor;

    let polygonPoints = [];
    for (const shop_type of shop_types) {
      const shopList = await getNearbySearch(google, map, location, shop_type);
      if (shop_type === 'restaurant') {
        foodCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {food_competitor: e.name, food_competitor_distance: distance};
          return competitor;
        });
        polygonPoints.push(...shopList.map(e => e.location));
      } else if (shop_type === 'supermarket') {
        supermarketCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {supermaket_competitor: e.name, supermaket_competitor_distance: distance};
          return competitor;
        });
      } else if (shop_type === 'home_goods_store') {
        homeGoodsStoreCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {homecenter_competitor: e.name, homecenter_competitor_distance: distance};
          return competitor;
        });
      } else if (shop_type === 'drugstore') {
        drugstoreCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {drug_store_competitor: e.name, drug_store_competitor_distance: distance};
          return competitor;
        });
      }
    }
    setNearShop({
      ...nearShop,
      [placeName]: {
        food_competitor_list: foodCompetitor,
        supermaket_competitor_list: supermarketCompetitor,
        homecenter_competitor_list: homeGoodsStoreCompetitor,
        drug_store_competitor_list: drugstoreCompetitor
      }
    });
    setNearShopMarker(polygonPoints, google, map);
    setCircle(new google.maps.LatLng(location.lat, location.lng), 1500, google, map);
    setIsNearbyReady({...isNearbyReady, [placeName]: true});
    setProgress(false);
  };

  const doGetNearestStation = (results) => {
    let centerPos = new google.maps.LatLng(selectedPlace.position);
    // let add =
    //   selectedPlace.name.indexOf('〒') > -1
    //     ? selectedPlace.name.split(' ')
    //     : selectedPlace.name.indexOf('、') > -1 ? selectedPlace.name.split('、') : [];
    // if (add.length === 1) {
    //   return this.setNearestStation(
    //     '海や砂漠などのアドレスなしの場所',
    //     null,
    //     null,
    //     null
    //   );
    // }
    // let mainName =
    //   add.length > 0
    //     ? add.slice(1, add.length).reduce((total, cur) => (total += cur))
    //     : selectedPlace.name;
    let dis = google.maps.geometry.spherical.computeDistanceBetween(
      centerPos,
      results[0].geometry.location
    );
    setNearestStation({'stationName': results[0].name, 'dis': dis});
    setStation({...station, [placeName]: {'stationName': results[0].name, 'dis': dis}});
  };

  const errGetNearestStation = (status) => {
    // let centerPos = new google.maps.LatLng(selectedPlace.position);
    // let post = selectedPlace.name.split(' ')[0].split('〒')[1];

    // console.log(post + 'の最寄駅を取得中(MILIZE-APIで)');
    // getStationWithES(
    //   selectedPlace.position,
    //   3,
    //   (stations) => {
    //     stations = stations.sort((a, b) => {
    //       let latA = a._source.lat;
    //       let lngA = a._source.lon;
    //       let latB = b._source.lat;
    //       let lngB = b._source.lon;
    //       let t1 = new google.maps.LatLng(latA, lngA);
    //       let d1 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t1
    //       );
    //       let t2 = new google.maps.LatLng(latB, lngB);
    //       let d2 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t2
    //       );
    //       return d1 - d2;
    //     });
    //     let t = new google.maps.LatLng(
    //       [stations[0]._source.lat],
    //       [stations[0]._source.lon]
    //     );
    //     let dis = google.maps.geometry.spherical.computeDistanceBetween(
    //       centerPos,
    //       t
    //     );
    //     setNearestStation({'stationName': stations[0]._source.station_name, 'dis': dis});
    //     setStation({...station, [placeName]: {'stationName': stations[0]._source.station_name, 'dis': dis}});
    //   },
    //   (status) => {setNearestStation({'stationName': null, 'dis': null});}
    // );
    setNearestStation({'stationName': null, 'dis': null});
  };

  // MapContainerとは違い、マーカーは設置しない
  const searchNearestStation = () => {
    let pos = selectedPlace.position;
    let pyrmont = new google.maps.LatLng(pos.lat, pos.lng);
    getNearestStation(
      google,
      map,
      pyrmont,
      doGetNearestStation,
      errGetNearestStation
    );
  }

  const setNearestStation = (station) => {
    const stationName = station !== null && 'stationName' in station ? station.stationName : null;
    const dis = station !== null && 'dis' in station ? station.dis : null;
    const per_minute = 80;

    let shopList = {
      ...shopInputDefault,
      ...shop[placeName],
      業態: placeName in shop && '業態' in shop[placeName] && shop[placeName]['業態'] ? shop[placeName]['業態'] : pullDownList['業態'][1],
      住所: placeName,
      敷地面積: area[placeName] !== undefined ? Number(area[placeName])
              : placeName in shop && '敷地面積' in shop[placeName] && shop[placeName]['敷地面積'] ? shop[placeName]['敷地面積']
              : null,
      間口: frontage[placeName] !== undefined ? Number(frontage[placeName])
          : placeName in shop && '間口' in shop[placeName] && shop[placeName]['間口'] ? shop[placeName]['間口']
          : null,
      駅名: shop[placeName] && shop[placeName]["駅名"] ? shop[placeName]["駅名"] : stationName,
      距離: shop[placeName] && shop[placeName]["距離"] ? shop[placeName]["距離"] : (dis !== null ? Number(dis.toFixed(0)) : null),
      徒歩: shop[placeName] && shop[placeName]["徒歩"] ? shop[placeName]["徒歩"] : (dis !== null ? Math.ceil(Number(dis.toFixed(0) / per_minute)) : null),
    };
    setKobunruiList(shopList['大分類']);
    setShopInput(shopList);
    setShowDialog(true);
    setCheckboxFlag(shop[placeName] && shop[placeName]["checkboxFlag"] ? shop[placeName]["checkboxFlag"] : tmp)
    setSaveAry(shop[placeName] && shop[placeName]["checkboxFlag"] ? {...shop[placeName]["checkboxFlag"]} : tmp)

    // クリアボタン用の初期値保存
    let shopDefaultList = {
      ...shopInputDefault,
      業態: pullDownList['業態'][1],
      住所: placeName,
      敷地面積: area[placeName] !== undefined ? Number(area[placeName])
              : placeName in shop && '敷地面積' in shop[placeName] && shop[placeName]['敷地面積'] ? shop[placeName]['敷地面積']
              : null,
      間口: frontage[placeName] !== undefined ? Number(frontage[placeName])
          : placeName in shop && '間口' in shop[placeName] && shop[placeName]['間口'] ? shop[placeName]['間口']
          : null,
      駅名: stationName,
      距離: dis !== null ? Number(dis.toFixed(0)) : null,
      徒歩: dis !== null ? Math.ceil(Number(dis.toFixed(0) / per_minute)) : null,
      checkboxFlag: tmp,
    };
    setShopInputDefault(shopDefaultList);
  };

  const openSubDialog = () => {
    if (nearestStation === null && station[placeName] === undefined) {
      searchNearestStation();
    } else if (station[placeName] !== undefined) {
      setNearestStation(station[placeName]);
    } else {
      setNearestStation(nearestStation);
    }
  };

  const getShopTypes = () => {
    let shopTypes = [];
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        shopTypes.push(key);
      }
    }
    return shopTypes;
  };
  const getNearShop = () => {
    setProgress(true);
    getNearByInfo(selectedPlace.position, getShopTypes());
  };

  const closeSubDialog = (event) => {
    if (shop[placeName]){
      shop[placeName]["checkboxFlag"] = saveAry
    }
    setShowDialog(false);
  };

  const saveInput = (event) => {
    let input = {...shopInput, ["checkboxFlag"]: checkboxFlag};
    for (let [key, value] of Object.entries(input)) {
      if (strShopInfo.indexOf(key) === -1 && typeof value === 'string') {
        if (value.substr(-1) == ".") {
          setErrMsg(key + "の少数部を入力してください。");
          setErrOpen(true);
          return;
        }
        if (value.indexOf(',') > 0) {
          value = value.replace(/,/g, '');
        }
        if (value !== '' && value !== ' ') input[key] = Number(value);
      }
    }
    setShop({...shop, [shopInput['住所']]: input});
    setIsInputReady({...isInputReady, [shopInput['住所']]: true});
    setShowDialog(false);
  };

  const setDefault = (event) => {
    setShopInput(shopInputDefault);
    setIsInputReady({...isInputReady, [shopInput['住所']]: false});
    let lst = pullDownList['小分類'].slice(0, 4);
    lst.unshift('');
    setTmpList(lst);
    setCheckboxFlag(tmp)
  }

  const convertIntTolocalestring = (value) => {
    let num = value.split(".")
    num[0] = Number(num[0]).toLocaleString()
    return num[0] + "."
  }

  const shopElement = (type, name, unit='', required=false, isFloat=false) => {
    if (name === '住所') {
      const elemNameClassName = required ? classes.shopInputDialogAddressNameCell + ' ' + classes.required : classes.shopInputDialogAddressNameCell;
      const elemValueClassName = classes.shopInputDialogAddressValueCell;
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>
            {name}
          </TableCell>
          <TableCell className={elemValueClassName}>
            <TextField
              required={KeyShopInfo.indexOf(name) > -1}
              inputProps={{style: {fontSize: '14px', padding: '2px'}}}
              InputProps={{classes: {root: classes.textField}}}
              margin='none'
              fullWidth
              id={name}
              variant="outlined"
              value={shopInput[name]}
              onChange={(event) => handleInputChange(event, isFloat)}
              onBlur={(event) => handleInputChange(event, isFloat)}
              style={{width: '98%', height: '22px', justifyContent: 'center', paddingLeft: '5px'}}
            />
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'text') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      return (
        <TableRow>
          {name === '出店階数' && (
            <TableCell className={elemNameClassName}>
              {name}
              <button style={{height: "23px"}} onClick={openModal}>選択</button>
            </TableCell>
          )}
          {name !== '出店階数' && (
            <TableCell className={elemNameClassName}>
              {name}
            </TableCell>
          )}
          <TableCell className={elemValueClassName}>
          {name === '坪数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 8, pattern: "^-?\d{1,}\.\d*$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputFloorSpace}
                id={name}
                value={shopInput[name] ? (typeof shopInput[name] == 'number' ? shopInput[name].toLocaleString() : (shopInput[name].substr(-1) == "." ? convertIntTolocalestring(shopInput[name]) : Number(shopInput[name]).toLocaleString())) : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, true)}
                onBlur={(event) => handleInputChange(event, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            {name === '出店階数' && (
              <span className={classes.floorBox}>{shopInput["出店階数"]}</span>
            )}
            {name === '客単価' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 7, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputCustomerSpend}
                id={name}
                value={shopInput[name] ? Number(shopInput[name]).toLocaleString() : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '保証金' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 11, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputDeposit}
                id={name}
                value={shopInput[name] ? Number(shopInput[name]).toLocaleString() : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '賃料(※2)' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 11, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputDeposit}
                id={name}
                value={shopInput[name] ? Number(shopInput[name]).toLocaleString() : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '席数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 3, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '敷地面積' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, true)}
                onBlur={(event) => handleInputChange(event, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            {name === '切下げ有接道数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 1, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '台数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '充足度' && (
              <TextField
                className={classes.inputBox}
                disabled
                inputProps={{style: { textAlign: 'right', fontSize: '14px', padding: '2px' }}}
                InputProps={{classes: {root: classes.textField, disabled: classes.disabledString}}}
                margin='none'
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(event) => handleInputChange(event, false)}
              />
            )}
            {name === '最寄駅' && (
              <TextField
                className={classes.inputNearStation}
                inputProps={{style: { fontSize: '14px', padding: '2px' }}}
                InputProps={{classes: {root: classes.textField}}}
                margin='none'
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                style={{width: '50%'}}
              />
            )}
            {name === '駅名' && (
              <TextField
                className={classes.inputStationName}
                inputProps={{style: { fontSize: '14px', padding: '2px' }}}
                InputProps={{classes: {root: classes.textField}}}
                margin='none'
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
              />
            )}
            {name === '乗降客数(※)' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 8, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputNearStation}
                id={name}
                value={shopInput[name] ? Number(shopInput[name]).toLocaleString() : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '距離' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 6, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputDistance}
                id={name}
                value={shopInput[name] ? Number(shopInput[name]).toLocaleString() : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '徒歩' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 3, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputWalk}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '間口' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(e) => handleInputChange(e, true)}
                onBlur={(e) => handleInputChange(e, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            {name === '交通センサス合計' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 7, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
                style={{width: '80px'}}
              />
            )}
            {name === 'トータル車線数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '店側車線数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '1車線当たり交通量' && (
              <TextField
                margin='none'
                disabled
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField, disabled: classes.disabledString}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '間口 ※車道のみ' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'number'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(e) => handleInputChange(e, true)}
                onBlur={(e) => handleInputChange(e, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'select') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      let shopInputElementName;
      if (name === 'メイン道路種類' || name === 'サブ1道路種類') {
        shopInputElementName = '種類';
      } else {
        shopInputElementName = name;
      }
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>
            {shopInputElementName}
          </TableCell>
          <TableCell className={elemValueClassName}>
            <Select
              name={name}
              value={shopInput[name] !== '' ? shopInput[name] : ''}
              onChange={handlePullDownChange}
              variant="outlined"
              margin='none'
              className={classes.select}
              classes={{root: classes.selectRoot, select: classes.selectLabel, disabled: classes.disabledString}}
              native
              disabled={ name === '大分類'}
            >
              {name !== '小分類' && pullDownList[name].map((val) => (
                <option value={val} key={val}>{val}</option>
              ))}
              {name === '小分類' && tmpList.map((val) => (
                <option value={val} key={val}>{val}</option>
              ))}
            </Select>
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'none') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>{name}</TableCell>
          <TableCell className={elemValueClassName}>{name}</TableCell>
        </TableRow>
      )
    }
  }

  const renderShopInput = () =>
    <React.Fragment>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>基本属性</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('select', '業態', '')}
            {shopElement('text',   '客単価', '円')}
            {shopElement('text',   '保証金', '円')}
            {shopElement('text',   '賃料(※2)', '円', true)}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text',   '坪数', '坪')}
            {shopElement('text',   '出店階数', '')}
            {shopElement('select', '休業・時短要請(※1)', '', true)}
            {shopElement('select', '営業時間', '時間')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Typography
          variant='body1'
          align='left'
          display='inline'
          style={{verticalAlign: 'middle', color:'#313de9', fontSize: '14px'}}
          className={classes.shopInputFont}
        >
          {'※1：コロナによる行政からの休業・時短などの要請有無'}
        </Typography>
        <br />
        <Typography
          variant='body1'
          align='left'
          display='inline'
          style={{ verticalAlign: 'middle', color: '#313de9', fontSize: '14px' }}
          className={classes.shopInputFont}
        >
          {'※2：共益費、管理費、看板代等がある場合は賃料に加算し、税込金額にて入力すること'}
        </Typography>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>最寄駅</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '駅名')}
            {shopElement('text', '乗降客数(※)', '人', true)}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '距離', 'm')}
            {shopElement('text', '徒歩', '分')}
          </TableBody>
        </Table>
      </Grid>
      <Typography
          variant='body1'
          align='left'
          display='inline'
          style={{verticalAlign: 'middle', color:'#313de9', fontSize: '14px'}}
          className={classes.shopInputFont}
        >
        {'※：複数路線乗り入れている場合は、最も乗降客数が多い鉄道会社を対象とすること'}
      </Typography>
      <Typography
          variant='body1'
          align='left'
          display='inline'
          style={{verticalAlign: 'middle', color:'#313de9', fontSize: '14px'}}
          className={classes.shopInputFont}
        >
        {'※：JRの場合は乗車数を2倍した値を入力すること'}
      </Typography>
    </React.Fragment>

  const dialogForm = () => {
    return (
      <React.Fragment>
        <Table>
          <TableBody>
            <TableRow className={classes.th1}>
              <TableCell className={classes.inputGroupTitle}>物件</TableCell>
            </TableRow>
            <TableRow className={classes.th2}></TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.dialogItemNameCell}>住所</TableCell>
              <TableCell className={classes.dialogItemValueCell}>
                {selectedPlace.name !== undefined ?
                  selectedPlace.name.split(' ')[1] : selectedPlace.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0 15px'}}>
          <React.Fragment>
          <Button
              onClick={openSubDialog}
              className={classes.subDialogButton}
              variant='contained'
              component="span"
              classes={{label: classes.subDialogButtonString}}
              disabled={progress}
              >
              入力
              </Button>
          </React.Fragment>
        </Grid>
        <Table>
          <TableBody>
            <TableRow className={classes.th1}>
              <TableCell className={classes.inputGroupTitle}>予測</TableCell>
            </TableRow>
            <TableRow className={classes.th2}></TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.dialogItemNameSmallCell}>物件入力</TableCell>
              <TableCell className={classes.dialogItemValueSmallCell + ' ' + (isInputReady[placeName] ? classes.readyColor : classes.notReadyColor)}>
                {isInputReady[placeName] ? '済' : '未'}
              </TableCell>
              <TableCell className={classes.dialogItemNameSmallCell}>周辺店舗取得</TableCell>
              <TableCell className={classes.dialogItemValueSmallCell + ' ' + (classes.unconcernedColor)}>
                {'-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.dialogItemNameCell}>予測金額</TableCell>
              <TableCell colSpan={3} className={classes.dialogItemValueCell}>
                <FormControl className={classes.formControl}>
                  {selectedPlace.name === undefined || predictionResult[selectedPlace.name.split(' ')[1]] === undefined ? '' : (Math.floor(predictionResult[selectedPlace.name.split(' ')[1]]['predictions']*1.2)).toLocaleString() + '円'}
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0 15px'}} spacing={1}>
          <Grid item>
            <Button
              onClick={handlePrediction}
              disabled={
                !(isInputReady[placeName])
              }
              variant='contained'
              className={classes.subDialogButton}
              classes={{label: classes.subDialogButtonString}}
            >
              {'実行'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleCrtPDF}
              variant='contained'
              disabled={!selectedPlace.name || !finishPrediction[selectedPlace.name.split(' ')[1]]}
              className={classes.subDialogButton}
              classes={{label: classes.subDialogButtonString}}
            >
              {'結果表出力'}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const checkKeyShopInput = () => {
    let keyVals = KeyShopInfo.map((key) => shopInput[key]);
    return keyVals.indexOf('') === -1;
  };

  const renderSubDialog = (
    <Dialog
      open={showDialog}
      onClose={closeSubDialog}
      aria-labelledby='form-dialog-title'
      classes={{paper: classes.subDialogPaper}}
    >
      <DialogTitle style={{padding: '0px'}}>
        <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
          <Grid item xs={6}>
            <Typography variant='body1' component='h6'>
              {'物件入力'}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
      {/* <Grid container style={{padding: '3px 16px'}}> */}
      <Grid container>
          <Grid item xs={6} style={{display: 'table'}}>
            {/*
            <Typography
              variant='body1'
              align='left'
              display='inline'
              style={{display: 'table-cell',verticalAlign: 'middle', fontWeight: 'bold'}}
              className={classes.shopInputFont}
            >
              {'※:項目名が青の項目は必須入力'}
            </Typography>
            */}
          </Grid>
          <Grid item xs={6}>
            <DialogActions style={{paddingRight: '0'}}>
              <Button
                onClick={saveInput}
                disabled={!checkKeyShopInput()}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString}}
              >
                  {'登録'}
              </Button>
              <Button
                onClick={setDefault}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString, disabled: classes.subDialogButtonDisabled}}
              >
                  クリア
              </Button>
              <Button
                onClick={closeSubDialog}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString}}
              >
                  {'閉じる'}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px', paddingBottom: '5px', border: 'solid lightgray 1px'}}>
          {renderShopInput()}
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const renderProgress = (
    <>
      <Dialog open={progress} classes={{paper: classes.progressDialog}}>
        <DialogTitle style={{background: 'lightgray', padding: '3px 13px'}}>
          <Typography variant='body1' component='div'>
            処理中
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )

  return (
    <div>
    <div style={{position: 'relateive', zIndex: '1000', background: 'white'}}>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title' classes={{paper: classes.dialogPaper}} style={{minWidth: '1000px', visibility: showDialog ? 'hidden' : 'visible'}}>
        <DialogTitle style={{padding: '0px'}}>
          <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
            <Typography variant='body1' component='h6'>
              {title}
            </Typography>
          </Grid>
          <Grid container style={{padding: '3px 8px', display: 'flex', justifyContent: 'flex-end'}}>
            <DialogActions classes={{root: classes.closeButtonPadding}}>
              <Button
                onClick={(e) => {
                  onClose();
                  setState({
                    restaurant: true,
                    supermarket: true,
                    home_goods_store: true,
                    department_store: false,
                    drugstore: true,
                  });
                  setErrMsg('');
                  setShopInput(shopInputDefault);
                }}
                variant='contained'
                className={classes.subDialogButton}
                classes={{label: classes.subDialogButtonString}}
                disabled={progress}
              >
                {'閉じる'}
              </Button>
            </DialogActions>
          </Grid>
        </DialogTitle>

        <DialogContent id='dialogContent' classes={{root: classes.dialogContentRoot}}>
          <Grid container style={{padding: '5px', border: 'solid lightgray 1px'}}>
            {dialogForm()}
          </Grid>
        </DialogContent>
      </Dialog>
      {renderSubDialog}
      {renderProgress}
      <PredContent
        id={'divtoPDF'}
        title={'予測結果'}
        name={'予測結果'}
        para={''}
        dataset={''}
        shopType={''}
        predictRes={placeName === '' || predictionResult[placeName] === undefined ? '' : predictionResult[placeName]}
        onOpenShopInfo={openSubDialog}
        photos={photos}
        memo={memo}
        shopInput={shopInput}
      />
      <Alert open={errOpen} handleClose={handleErrDialogClose} msg={errMsg} okBtnName={'閉じる'} />
    </div>
      <Modal
        open={handleModal}
        onClose={closeModal}
      >
        <div className={classes.modal}>
          <div className={classes.modalTitle}>出店階数</div>
          <div style={{marginLeft: "10px"}}>
            <button onClick={registerModal} className={classes.modalButton}>登録</button>
            <button onClick={closeModal} className={classes.modalButton}>閉じる</button>
          </div>
          <FormGroup style={{marginLeft: "5px"}}>
            {pullDownList["出店階数"].map((val, i) => (
              <FormControlLabel
                control={
                  <Checkbox
                    id={val}
                    defaultChecked={checkboxFlag[i]}
                    name="floor"
                    color='primary'
                    classes={{ root: classes.checkbox }}
                  />
                }
                key={val}
                label={val}
                classes={{label: classes.formControlLabelString}}
                className={i > 12 && classes.checkboxPosition}
                disabled={progress}
              />
            ))}
          </FormGroup>
        </div>
      </Modal>
    </div>
  );
}
