import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  Form,
  Chart1,
  Chart2,
  Input,
  Button,
  Error,
  Paper,
  Title,
  Content,
  Lower,
  SubTitle,
  PassWordTitle,
  PasswordInput
} from "../components/UserAuthForm";
import axios from "axios";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import SimpleBackdrop from "../components/CirProgress";
import Dialog from "@material-ui/core/Dialog";
import { errorLog } from '../components/ErrorLog'
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import RegisterMark from '../images/register_mark.svg';

const useStyles = makeStyles((theme) => ({

  dialogBackground: {
    minWidth: "100vw",
    height: "100vh",
    background: "#F4F7FF",
    fontFamily: "Noto Sans JP",
    padding: "unset !important",
    justifyContent: "center",
  }

}));


function ResetPwd(props) {
  const classes = useStyles();
  const [isReset, setReset] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [new_password, setNewPassword] = useState("");
  const [again_password, setAgainPassword] = useState("");
  const [hasSent, setHasSent] = useState(false);
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  const [isChkUrl, setIsChkUrl] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const onOpenSendModal = () => setOpenSend(true);
  const onCloseSendModal = () => setOpenSend(false);

  function visitPage() {
    window.location = "/ai-shop-development/login";
  }

  if (isReset) {
    return <Redirect to="/ai-shop-development/login" />;
  }

  // 現在時刻取得
  function date_get_today() {
    var date = new Date();
    // フォーマット変更
    var r_format_date = date_format_change(date);
    return r_format_date;
  }

  // フォーマット変更
  function date_format_change(date) {
    let year_str = date.getFullYear();
    // 月だけ+1すること
    let month_str = 1 + date.getMonth();
    let day_str = date.getDate();
    let hour_str = date.getHours();
    let minute_str = date.getMinutes();
    let second_str = date.getSeconds();

    let format_str = "YYYY/MM/DD hh:mm:ss";
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str;
  }

  function postResetPwd() {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("user_id");
    const token = urlParams.get("token");
    const update_user_id = user_id;
    const update_date = date_get_today();
    setShowProgBar(true);
    axios
      .request({
        method: "put",
        url: process.env.REACT_APP_AUTH_SERVER + "auth/resetPwd",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          user_id,
          new_password,
          again_password,
          update_user_id,
          update_date,
        },
      })
      .then((result) => {
        if (result.status === 200) {
          //setHasSent(true);
          onOpenSendModal();
        } else {
          alert(result.data.message);
        }
        setShowProgBar(false);
      })
      .catch((e) => {
        alert(e.response.data.message);
        setShowProgBar(false);
        switch (e.response?.status) {
          case 400:
          case 401:
            errorLog(true,token,"18")
            setAuthTokens(null);
            setSignedInUser(null);
        }
      });
  }

  if(!isChkUrl){
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("user_id");
    const token = urlParams.get("token");
    const onetimekey = urlParams.get("onetimekey");
    if(typeof user_id!="string"||typeof token!="string"){
      if(typeof onetimekey=="string"){
        axios
        .post(process.env.REACT_APP_AUTH_SERVER + "auth/tmp_password_reset_chk", {
          onetimekey,
        })
        .then((result) => {
          if (result.status === 200) {
            if (result.data.token.length > 0) {
              window.location = `/ai-shop-development/reset-pwd?user_id=${result.data.user_id}&token=${result.data.token}`;
            }
            setShowProgBar(false);
          } else {
            alert(result.data.message);
            setShowProgBar(false);
          }
        })
        .catch((e) => {
          alert(e.response.data.message)
          window.location = "/ai-shop-development/login";
        });
        setIsChkUrl(true);
      }else{
        alert("無効なURLです。")
        window.location = "/ai-shop-development/login";
      }
    }else{
      setIsChkUrl(true);
    }
  }

  const referer =
    props.location.state !== undefined
      ? props.location.state.referer
      : "/ai-shop-development";

  if (hasSent) {
    return <Redirect to={referer} />;
  }

  const password_reset_modal = {
    modal: {
      height: "285px",
      zIndex: "1500"
    }
  }

  return (
    <div>
      <Dialog open fullScreen={true}>
        <SimpleBackdrop open={showProgBar} />
        <Card className={classes.dialogBackground}>
          <div className="centering-items">
            <Title className="head_title">■ パスワード変更</Title>
            <Content>
              <SubTitle>新パスワード</SubTitle>
              <Form>
                <Input
                  type="password"
                  value={new_password}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </Form>
              <SubTitle>確認パスワード</SubTitle>
              <Form>
                <Input
                  type="password"
                  value={again_password}
                  onChange={(e) => {
                    setAgainPassword(e.target.value);
                  }}
                />
              </Form>
            </Content>
            <Lower className="centering-items centering-items-row">
              <div className="submit_btn sp_submit_btn" onClick={postResetPwd}>変更する</div>
              <div className="submit_btn sp_submit_btn" onClick={visitPage}>戻る</div>
            </Lower>
            <Modal open={openSend} onClose={onCloseSendModal} center styles={password_reset_modal}>
              <img src={RegisterMark} alt="register"/>
              <div className="modal_header">パスワード変更完了</div>
              <div className="modal_text">パスワードの変更が完了しました。<br />変更後のパスワードにてログインください</div>
              <div className="modal_submit_btn" onClick={visitPage}><span className="close_btn">もどる</span></div>
            </Modal>
          </div>
        </Card>
      </Dialog>
    </div>
  );
}
export default ResetPwd;
