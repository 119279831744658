import axios from 'axios';
import { errorLog } from '../components/ErrorLog'

export const predict = async (shopInputData, userId, token, setAuthTokens, setSignedInUser) => {
  try {
    const results = await axios.post(
      process.env.REACT_APP_AI_API + '/predict',
      shopInputData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          UserId: `${userId}`,
          ContentType: 'application/json',
        }

      }
    )
    return results;
  } catch (err) {
    if (err.toString().indexOf('400') > 0 || err.toString().indexOf('401') > 0) {
      errorLog(true,token,"20")
      setSignedInUser(null);
      setAuthTokens(null);
    }
    throw err;
  }
};