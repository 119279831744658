import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';
import { errorLog } from './ErrorLog'

const useStyles = makeStyles((theme) => ({
  dialogContentRoot: {
    padding: '8px 24px 12px',
  },
  dialogPaper: {
    minWidth: '600px',
  },
  closeButtonPadding: {
    padding: '8px 0px',
  },
  dialogButton: {
    fontWeight: 'normal',
    height: '30px',
    padding: '3px 13px',
  },
  deletedialogButton: {
    fontWeight: 'normal',
    height: '30px',
    padding: '3px 13px',
    marginBottom: '5px',
  },
  dialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  deletedialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '600',
    color:'#dc3545',
  },
  inputGroupTitle: {
    textAlign: 'center',
    height: '30px',
    padding: '0px',
    color: 'gray',
    borderBottom: 'solid 1px darkgrey',
  },
  th1: {
    fontSize: '14px',
    color: 'gray',
    background: 'lightgray',
    fontWeight: 'lighter',
    textDecoration: 'underline',
    border: 'solid 1px darkgrey',
  },
  th2: {
    border: 'none',
    height: '5px',
  },
  th3: {
    border: 'none',
    height: '15px',
  },
  fileNameCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '40%',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  registerDateTimeCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '40%',
    height: '30px',
  },
  buttonCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '10%',
    height: '30px',
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
}));

function Document(props) {
  const classes = useStyles();
  const [isboolen, setBoolen] = useState(false);
  const { authTokens, setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  const Register_change = () => {
    if (props.register_flags[props.document_id]){
      props.register_flags[props.document_id] = false
    }
    else{
      props.register_flags[props.document_id] = true;
    }
    //強制レンダリング
    setBoolen(!isboolen);
  }

  //削除ボタン押下時処理
  const handleDeleteChange = () => {
    var result = window.confirm("削除してよろしいですか？");
    if (result){
      //削除する。
      handleDeleteImageFile();
    }
    else{
      ;//削除しない。
    }
  }

  const handleShowImage = () => {
    var i = new Image();
    i.onload = function(){
      //別ウィンドウに表示
      var newTab= window.open('',"_blank",'');
      if (i.width > 800){
        newTab.document.body.innerHTML= "<html lang='en'><head><title>資料表示</title></head><img src='"+ props.Images[props.document_id][2] + "'width=800px"  + "> </img></html>";
      }
      else{
        newTab.document.body.innerHTML= "<html lang='en'><head><title>資料表示</title></head><img src='"+ props.Images[props.document_id][2] + "'width=" + i.width + "> </img></html>";
      }
    };
    i.src = props.Images[props.document_id][2];
  };

  //削除ボタン押下時DBに再登録する。
  const register_delete = (sort_hairetu) => {
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const current_MarkerName = localStorage.getItem('currentMarkerName');
    const uid = cur_user.uid;
    //削除
    axios.request({
      method: 'delete',
      url: process.env.REACT_APP_AUTH_SERVER+'places/del',
      data: {pid: current_MarkerName, uid: uid},
      headers: {
        Authorization: `Bearer ${authTokens}`,
        ContentType: 'application/json',
      }
    })
    .then((res) => {
      //登録
      axios
      .post(process.env.REACT_APP_AUTH_SERVER+'places/add', {
        pid:      current_MarkerName,
        fields:   '',
        imgs:     sort_hairetu,
        location: '',
        memo:     props.memos,
        name:     current_MarkerName,
        uid:      uid,
      },{
        headers: {
          Authorization: `Bearer ${authTokens}`,
          ContentType: 'application/json',
        }
      })
    }).catch((error) => {
      switch (error.response?.status) {
        case 400:
        case 401:
          errorLog(true,authTokens,"12")
          setAuthTokens(null);
          setSignedInUser(null);
      }
    })
  }

  // 削除ボタン押下時要素を並び直す
  const sort_delete = () => {
    //列を振り直す。　
    props.Images[props.document_id][0] = '';
    props.Images[props.document_id][1] = '';
    props.Images[props.document_id][2] = '';

    let sort_hairetu = []
    for( const elem of props.Images){
      if (elem[0] != '' || elem[1] != '' || elem[2] != ''){
        sort_hairetu.push(elem);
      }
    }
    while (sort_hairetu.length < 10){
      sort_hairetu.push(['','','']);
    }
    props.setImages(sort_hairetu);
    //配列を振り直す。
    register_delete(sort_hairetu);
  }

  // 削除ボタン押下時フラグを立てる。
  const flag_delete_set = () => {
    let flag_hairetu = [];
    let count = 0;
    //取り込まれている画像によってフラグを立てる。
    props.Images.forEach((value, index, array) => {
      if (value[0] != '' || value[1] != '' || value[2] != ''){
        flag_hairetu.push(true);
      }
      else if (count == 0) {
        flag_hairetu.push(true);
        count += 1;
      }
      else{
        flag_hairetu.push(false);
      }
    })
    props.setTorikomiFlags(flag_hairetu);

    //表示フラグを振り直す
    let flag_count = 0;
    props.Images.forEach((value, index, array) => {
      if (value[0] != '' || value[1] != '' || value[2] != ''){
        flag_count += 1
      }
    })
    let hairetu_flag = []
    for (let step = 0; step < 10; step++) {
      if(flag_count > 0){
        hairetu_flag.push(true);
        flag_count -= 1
      }
      else{
        hairetu_flag.push(false);
      }
    }
    props.setRegisterFlags(hairetu_flag);
  }

  // 削除ボタン
  const handleDeleteImageFile = () => {
    sort_delete();
    //取り込みフラグを振り直す
    flag_delete_set();
  }

  //日付の0埋め
  const toDoubleDigits = function(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
   return num;
  };

  // 時刻表示変更
  const date_display_change = (date) =>{
    let year_str = date.getFullYear();
    // 月だけ+1すること
    let month_str  = toDoubleDigits(1 + date.getMonth());
    let day_str    = toDoubleDigits(date.getDate());
    let hour_str   = toDoubleDigits(date.getHours());
    let minute_str = toDoubleDigits(date.getMinutes());
    let second_str = toDoubleDigits(date.getSeconds());

    let format_str = 'YYYY/MM/DD hh:mm:ss';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str
  }

  //取り込み不可時フラグを戻す
  const torikomi_flag_redefine = () => {
    let count = 0;
    props.register_flags.forEach((value, index, array) => {
      if (value){
        count += 1
      }
    });
    let ret_hairetu = []
    while (count > 1){
      ret_hairetu.push(true);
      count -= 1
    }
    while (ret_hairetu.length < 10){
      ret_hairetu.push(false);
    }
    props.setRegisterFlags(ret_hairetu);
    props.setTorikomiFlags(props.torikomi_flags);
  }

  //画像ファイルをDBに登録する。
  const image_db_register = () => {
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const current_MarkerName = localStorage.getItem('currentMarkerName');
    const uid = cur_user.uid;
    //登録される前のデータを保持する。
    let before_Images = JSON.parse(JSON.stringify(props.Images));
    before_Images[props.document_id][0] = '';
    before_Images[props.document_id][1] = '';
    before_Images[props.document_id][2] = '';
    let before_memos  = props.memos;

    //削除
    axios.request({
      method: 'delete',
      url: process.env.REACT_APP_AUTH_SERVER+'places/del',
      data: {pid: current_MarkerName, uid: uid},
      headers: {
        Authorization: `Bearer ${authTokens}`,
        ContentType: 'application/json',
      }
    })
    .then((result) => {
      //登録
      axios
      .post(process.env.REACT_APP_AUTH_SERVER+'places/add', {
        pid:      current_MarkerName,
        fields:   '',
        imgs:     props.Images,
        location: '',
        memo:     props.memos,
        name:     current_MarkerName,
        uid:      uid,
      },{
        headers: {
          Authorization: `Bearer ${authTokens}`,
          ContentType: 'application/json',
        }
      })
      .then((result) => {
        ;// pass
      })
      .catch((e) => {
        alert('取り込みができませんでした。');
        //登録できなかった時に現在の値登録
        axios
        .post(process.env.REACT_APP_AUTH_SERVER+'places/add', {
          pid:      current_MarkerName,
          fields:   '',
          imgs:     before_Images,
          location: '',
          memo:     before_memos,
          name:     current_MarkerName,
          uid:      uid,
        },{
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        })
        .then((result) => {
          // reloadメソッドによりページをリロード
          //現在の値に戻している
          props.setImages(before_Images);
          torikomi_flag_redefine();
        })
      });
    })
  }

  // 画像ファイルをバイナリデータに変換する。
  const bainari_change = (file) => {
    // ファイルリーダー作成
    var fileReader = new FileReader();
    fileReader.onload = function() {
        // Data URIを取得
        var dataUri = this.result;
        props.Images[props.document_id][2] = dataUri;
        //画像をDBに保存
        image_db_register();
    }
    fileReader.readAsDataURL(file);
  }

  //画像取込時の取り込みフラグを立てる。
  const torikomi_flag_set = () => {
    //取り込み用のフラグを立てる。
    let hairetu = []
    props.torikomi_flags.forEach((value, index) =>{
      if (index == props.document_id + 1){
        hairetu.push(true);
      }
      else{
        hairetu.push(value);
      }
    })
    props.setTorikomiFlags(hairetu);
  }

  // 画像取り込み　
  const handleUploadImageFile = (e) => {
    let file = e.target.files[0];
    torikomi_flag_set();
    let file_days = new Date(file.lastModified);
    // 画像名前は0 画像パスは1 画像バイナリは2
    props.Images[props.document_id][0] = file.name;
    props.Images[props.document_id][1] = date_display_change(file_days);
    bainari_change(file);
    props.setImages(props.Images);
    Register_change();
  }

  return (
    <TableRow>
      <TableCell className={classes.fileNameCell}>{props.Images[props.document_id][0]}</TableCell>
      <TableCell className={classes.registerDateTimeCell}>{props.Images[props.document_id][1]}</TableCell>
      <TableCell
        className={classes.buttonCell}
      >
        {props.register_flags[props.document_id] && (
          <Button
            variant='contained'
            className={classes.dialogButton}
            classes={{label: classes.dialogButtonString}}
            onClick={handleShowImage}
          >
            表示
          </Button>
        )}
        {!props.register_flags[props.document_id] && (
          <Button
            component="label"
            variant='contained'
            className={classes.dialogButton}
            classes={{label: classes.dialogButtonString}}
            disabled={!props.torikomi_flags[props.document_id]}
          >
          <input
            accept="image/*"
            className={classes.input}
            type="file"
            onChange={handleUploadImageFile}
          />
            取込
          </Button>
        )}
      </TableCell>
      <TableCell
        className={classes.buttonCell}
      >
        <Button
          variant='contained'
          className={classes.dialogButton}
          classes={ !props.register_flags[props.document_id] ?  {label: classes.dialogButtonString} : {label: classes.deletedialogButtonString} }
          disabled={!props.register_flags[props.document_id]}
          onClick={handleDeleteChange}
        >
          削除
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default function RegisterDocument(props) {
  const classes = useStyles();
  const [currentMemo, setCurrentMemo] = useState(props.register_memo);
  const {
    open,
    onClose,
  } = props;
  const [all_images_name_lst, setAllNameImagelst] = useState(props.register_imgs);
  const [register_flags, setRegisterFlags]     = useState([false,false,false,false,false,false,false,false,false,false]);
  const [torikomi_all_flags, setTorikomiFlags] = useState([false,false,false,false,false,false,false,false,false,false]);
  const { authTokens } = useAuth();

  // メモ書き込み
  const memoWrite = (e) => {
    setCurrentMemo(e.target.value);
  }

  // 資料登録押下時フラグを立てる。
  const document_register_flags = () => {
    //取り込まれている画像によってフラグを立てる。
    let flag_hairetu = [];
    props.register_imgs.forEach((value, index, array) => {
      if (value[0] != '' || value[1] != '' || value[2] != ''){
        flag_hairetu.push(true);
      }
      else{
        flag_hairetu.push(false);
      }
    })
    setRegisterFlags(flag_hairetu);

    flag_hairetu = [];
    let count = 0;
    //取り込まれている画像によってフラグを立てる。
    props.register_imgs.forEach((value, index, array) => {
      if (value[0] != '' || value[1] != '' || value[2] != ''){
        flag_hairetu.push(true);
      }
      else if (count == 0) {
        flag_hairetu.push(true);
        count += 1;
      }
      else{
        flag_hairetu.push(false);
      }
    })
    setTorikomiFlags(flag_hairetu);
  }

  // 資料登録押下時に情報が取り込まれるように設定
  useEffect(() => {
    setAllNameImagelst(props.register_imgs);
    setCurrentMemo(props.register_memo);
    document_register_flags();
  },[props.register_imgs])

  // 画像登録
  const image_registration = () => {
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const current_MarkerName = localStorage.getItem('currentMarkerName');
    const uid = cur_user.uid;
    let reRegister_memo   = currentMemo;
    let reRegister_images = JSON.parse(JSON.stringify(all_images_name_lst));
    axios.request({
      method: 'delete',
      url: process.env.REACT_APP_AUTH_SERVER+'places/del',
      data: {pid: current_MarkerName, uid: uid},
      headers: {
        Authorization: `Bearer ${authTokens}`,
        ContentType: 'application/json',
      },
    })
    .then((result) => {
      // 削除実行後登録する。
      axios
      .post(process.env.REACT_APP_AUTH_SERVER+'places/add', {
        pid:      current_MarkerName,
        fields:   '',
        imgs:     all_images_name_lst,// ここを全体にすればいい。
        location: '',
        memo:     currentMemo,
        name:     current_MarkerName,
        uid:      uid,
      },{
        headers: {
          Authorization: `Bearer ${authTokens}`,
          ContentType: 'application/json',
        }
      })
      .then((result) => {
      })
      .catch((e) => {
        //データの再登録
        //登録前に削除で消してしまうため。　
        axios
        .post(process.env.REACT_APP_AUTH_SERVER+'places/add', {
          pid:      current_MarkerName,
          fields:   '',
          imgs:     reRegister_images,
          location: '',
          memo:     reRegister_memo,
          name:     current_MarkerName,
          uid:      uid,
        },{
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        })
        .then((result) => {
          ;//pass
        })
        .catch((e) => {
          ;//pass
        });
      });
    })
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      classes={{paper: classes.dialogPaper}}
    >
      <DialogTitle style={{padding: '0px'}}>
        <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
          <Typography variant='body1' component='div'>
            資料登録
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <Grid container style={{padding: '3px 0px', display: 'flex', justifyContent: 'flex-end'}}>
          <DialogActions classes={{root: classes.closeButtonPadding}} style={{padding: '0px', paddingBottom: '5px'}}>
            <Button
              onClick={(e) => {
                onClose();
              }}
              variant='contained'
              className={classes.dialogButton}
              classes={{label: classes.dialogButtonString}}
              style={{minWidth: '100px',}}
            >
              {'閉じる'}
            </Button>
          </DialogActions>
        </Grid>
        <Grid container style={{padding: '5px', border: 'solid lightgray 1px'}}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                <TableRow className={classes.th1}>
                  <TableCell className={classes.inputGroupTitle}>資料</TableCell>
                </TableRow>
                <TableRow className={classes.th2}></TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {0}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {1}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {2}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {3}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {4}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {5}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {6}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {7}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {8}/>
                <Document
                setImages={setAllNameImagelst}
                Images= {all_images_name_lst}
                register_flags={register_flags}
                setRegisterFlags={setRegisterFlags}
                torikomi_flags={torikomi_all_flags}
                setTorikomiFlags={setTorikomiFlags}
                memos={currentMemo}
                document_id = {9}/>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <TableRow className={classes.th3}></TableRow>
                <TableRow className={classes.th1}>
                  <TableCell className={classes.inputGroupTitle}>メモ</TableCell>
                </TableRow>
                <TableRow className={classes.th2}></TableRow>
              </TableBody>
            </Table>
            <textarea maxLength="1000000" style={{width: '100%', height: '100px'}} value = {currentMemo} onChange={e => memoWrite(e)}></textarea>
          </Grid>
          <Grid container style={{padding: '3px 0', display: 'flex', justifyContent: 'flex-end'}}>
            <DialogActions
              classes={{root: classes.closeButtonPadding}}
              style={{paddingRight: '0', paddingBottom: '0', paddingTop: '0'}}
            >
              <Button
                variant='contained'
                className={classes.dialogButton}
                classes={{label: classes.dialogButtonString}}
                onClick={() => image_registration()}
                style={{minWidth: '100px'}}
              >
                メモ登録
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}