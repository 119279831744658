import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthContext } from './context/auth';
import { UserContext } from './context/user';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import ResetPwd from './pages/ResetPwd';
import ForgetPwd from './pages/ForgetPwd';
import SendResetMail from './pages/SendResetMail';

function App(props) {
  const existingTokens = localStorage.getItem('tokens');
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [signedInUser, setSignedInUsers] = useState({});

  const setTokens = (data) => {
    setAuthTokens(data);
    if (data === null) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('setupTime');
    } else {
      localStorage.setItem('tokens', data);
    }
  };

  const setUser = (data) => {
    setSignedInUsers(data);
    if (data === null) {
      localStorage.removeItem('user');
      localStorage.removeItem('setupTime');
    } else {
      localStorage.setItem('user', JSON.stringify(data));
    }
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <UserContext.Provider value={{ signedInUser, setSignedInUser: setUser }}>
        <Router>
          <PrivateRoute exact path='/ai-shop-development' component={Home} />
          <Route path='/ai-shop-development/login' component={Login} />
          <Route path='/ai-shop-development/signup' component={Signup} />
          <Route path='/ai-shop-development/sendreset-pwd' component={SendResetMail} />
          <Route path='/ai-shop-development/reset-pwd' component={ResetPwd} />
          <Route path='/ai-shop-development/forget-pwd' component={ForgetPwd} />
        </Router>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
