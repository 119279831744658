import axios from 'axios';

export const errorLog = (delFlag,authTokens,errorText) => {
  axios.post(
    process.env.REACT_APP_AUTH_SERVER + 'log/error-log',
    {
     "errorText": errorText,
     "uid": JSON.parse(localStorage.getItem('user')) != null ? JSON.parse(localStorage.getItem('user')).uid : "unknown",
     "delFlag": delFlag
    },
    {
      headers: {
        Authorization: `Bearer ${authTokens}`,
        ContentType: 'application/json',
      }
    }
  );
}