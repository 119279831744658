import React, { ChangeEvent, useState } from "react";
import { Redirect } from "react-router-dom";
import classNames from 'classnames';
import axios from "axios";
import {
  Card,
  Form,
  Chart1,
  Chart2,
  Input,
  Button,
  Error,
  Paper,
  Title,
  Content,
  Lower,
  SubTitle,
} from "../components/UserAuthForm";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import SimpleBackdrop from "../components/CirProgress";
import Dialog from "@material-ui/core/Dialog";
import { errorLog } from '../components/ErrorLog'
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import BackArrow from '../images/back_to_btn_20.svg';
import AirPlane from '../images/paper_airplane.svg';
import LeftButton from '../images/left_btn_24.svg';

const useStyles = makeStyles((theme) => ({

  dialogBackground: {
    minWidth: "100vw",
    height: "100vh",
    background: "#F4F7FF",
    fontFamily: "Noto Sans JP",
    padding: "unset !important",
    justifyContent: "center",
  }
  
}));

function SendResetMail(props) {
  const classes = useStyles();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [user_id, setUserId] = useState("");

  // 確認モーダル制御用
  const [openSend, setOpenSend] = useState(false);
  const onOpenSendModal = () => setOpenSend(true);
  const onCloseSendModal = () => setOpenSend(false);

  // 処理終了モーダル制御用
  const [openCheck, setOpenCheck] = useState(false);
  const onOpenCheckModal = () => setOpenCheck(true);
  const onCloseCheckModal = () => setOpenCheck(false);

  const tmpPasswordReset = () => {
    const mail = user_id;
    onCloseCheckModal();
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + 'auth/tmp_password_reset', {
        mail: mail,
      }, { headers: { 'content-type': 'application/json;charset=utf-8' } })
      .then((result) => {
        if (result.status === 200) {
          onOpenSendModal();
        } else {
          alert(result.message);
          onCloseSendModal();
        }
      })
      .catch((e) => {
        alert(e.response.data.message);
        onCloseSendModal();
        //onCloseSendModal();
      });
  }

  // モーダルの高さを制御
  const password_reset_modal = {
    modal: {
      height: "298px",
      zIndex: "1500"
    }
  }

  function moveLogin() {
    window.location = `/ai-shop-development/login`;
  }

  const referer =
    props.location.state !== undefined
      ? props.location.state.referer
      : "/ai-shop-development";

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <div>
      <Dialog open fullScreen={true}>
        <SimpleBackdrop open={showProgBar} />
        <Card className={classes.dialogBackground}>
          <div className="centering-items">
            <Title className="head_title">■ パスワード再設定</Title>
            <div className="head_text">ご登録のメールアドレスを入力してください。<br/>パスワード再設定用のメールをお送りします。</div>
            <Content>
                <SubTitle>メールアドレス</SubTitle>
                <Form>
                  <Input
                    type="mail"
                    name={user_id}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                  />
                </Form>
            </Content>
            <Lower className="centering-items centering-items-row">
              <div className="submit_btn sp_submit_btn" onClick={onOpenCheckModal}>再設定メールを送信</div>
            </Lower>
            <div className="centering-items">
              <div onClick={moveLogin} className="reset_btn">
              <img src={BackArrow} alt="arrow" className="back_btn" />
                <div className="reset_btn_text">
                  戻る
                </div>
              </div>
            </div>
            <div className="centering-items">
              <Modal open={openCheck} onClose={onCloseSendModal} center>
                <div className="modal_header modal_header_reset">メールを送信してよろしいですか？</div>
                <button className="modal_yes_btn" onClick={tmpPasswordReset}><span className="yes_text">はい</span></button>
                <button className="modal_no_btn" onClick={onCloseCheckModal}><span className="no_text">いいえ</span></button>
              </Modal>
              <Modal open={openSend} onClose={onCloseSendModal} center styles={password_reset_modal}>
                <span><img src={AirPlane} alt="airplane"/></span>
                <div className="modal_header">メールを送信しました。</div>
                <div className="modal_text modal_text_reset">メールに記載されている認証用URLにアクセスし、パスワードを再設定してください。</div>
                <div className="modal_close_btn modal_close_btn_reset" onClick={moveLogin} type="submit"><img src={LeftButton} alt="LeftButton" className="left_btn"/><span className="close_btn">とじる</span></div>
              </Modal>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  );
}
export default SendResetMail;
