import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, Logo, Form, Input, Button, Error } from '../components/AuthForm';
import axios from 'axios';
import SimpleBackdrop from '../components/CirProgress';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';
import { errorLog } from '../components/ErrorLog'

function Signup(props) {
  const [isRegisted, setRegisted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [againPassword, setAgainPassword] = useState('');
  const [msg, setMSG] = useState('');
  const [showProgBar, setShowProgBar] = useState(false);
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  if (isRegisted) {
    return <Redirect to='/ai-shop-developmen/login' />;
  }

  function postSignUp() {
    setShowProgBar(true);
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + 'users/register', {
        email,
        password,
        firstName,
        lastName,
      })
      .then((result) => {
        setShowProgBar(false);
        if (result.data.result === 'Success') {
          setRegisted(true);
        } else {
          setIsError(true);
          setMSG(result.data.message);
        }
      })
      .catch((e) => {
        setIsError(true);
        setMSG(e.message);
        setShowProgBar(false);
        switch (e.response?.status) {
          case 400:
          case 401:
            const token = localStorage.getItem('tokens')
            errorLog(true,token,"19")
            setAuthTokens(null);
            setSignedInUser(null);
        }
      });
  }
  return (
    <div>
      <Card>
        <Logo />
        <Form>
          <Input
            type='email'
            placeholder='email'
            value={email}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
          <Input
            type='text'
            placeholder='firstName'
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <Input
            type='text'
            placeholder='lastName'
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <Input
            type='password'
            placeholder='password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Input
            type='password'
            placeholder='password again'
            value={againPassword}
            onChange={(e) => {
              setAgainPassword(e.target.value);
            }}
          />
          <Button onClick={postSignUp}>登録</Button>
        </Form>
        <Link to='/ai-shop-development/login'>ログイン</Link>
        {isError && <Error>{msg}</Error>}
      </Card>
      <SimpleBackdrop
        open={showProgBar}
      />
    </div>
  );
}

export default Signup;
