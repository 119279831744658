import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Draggable from 'react-draggable';
import PhotoUploader from './PhotoUploader'
import axios from 'axios';
import { useAuth } from '../context/auth';
import { errorLog } from './ErrorLog'

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export class DraggableDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.visible,
            memo: props.memo,
            savedPhotos: props.photos,
            savedPhotoNum: props.savedPhotoNum,
            canUpload: false,
        };
        this.photos = React.createRef();
    }

    handleClose = (props) => {
        this.setState({
            open: false
        })
        this.props.onClose()
    }

    handleUpdated = (props) => {
        this.setState({
            open: false
        })
        this.props.onUpdated()
    }

    handleUpload = (e) => {
        const cur_user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('tokens');
        const uid = cur_user.uid;
        const pid = this.props.place.pid || '';
        const name = this.props.place.title;
        const location = this.props.place.position;
        let formData = new FormData();
        formData.append('uid', uid);
        formData.append('pid', pid);
        formData.append('name', name);
        formData.append('memo', this.state.memo);
        formData.append('location', JSON.stringify(location));
        [...this.photos.current.state.files].forEach((f) => formData.append('photos', f, f.name));

        axios
            .post(process.env.REACT_APP_AUTH_SERVER + 'files/upload-place-photos',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        ContentType: 'application/json',
                    }
                }
            )
            .then((res) => {
                console.log(res);
                this.handleUpdated();
            })
            .catch(error => {
                switch (error.response?.status) {
                    case 400:
                    case 401:
                        errorLog(true,token,"4")
                        this.props.setAuthTokens(null);
                        this.props.setSignedInUser(null);
                }
                console.log(error);
            });
    };

    onChangeMemo = (e) => {
        this.setState({
            memo: e.target.value,
            canUpload: true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            console.log("saved photos num:", this.props.savedPhotoNum)
            this.setState({
                open: this.props.visible,
                savedPhotos: this.props.photos,
                savedPhotoNum: this.props.savedPhotoNum,
                memo: this.props.memo,
            });
        }
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.place.name}
                    </DialogContentText>
                    <DialogContentText>
                        メモ追加
                    </DialogContentText>
                    <TextareaAutosize
                        rowsMax={10}
                        aria-label="maximum height"
                        placeholder="Maximum 10 rows"
                        defaultValue={this.state.memo}
                        style={{ width: "100%" }}
                        onChange={this.onChangeMemo}
                    />
                    <PhotoUploader
                        ref={this.photos}
                        place={this.props.place}
                        savedPhotos={this.state.savedPhotos}
                        savedPhotoNum={this.state.savedPhotoNum}
                        enableUpload={e => this.setState({ canUpload: true })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleClose} color="primary">
                        キャンセル
                </Button>
                    <Button onClick={this.handleUpload} disabled={!this.state.canUpload} color="primary">
                        アップロード
                </Button>
                </DialogActions>
            </Dialog>

        );
    }
}
export default DraggableDialog;